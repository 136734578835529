import styles from '../styles/MenuButton.module.css';
import VSpace from './VSpace.js';
import { useSpring, animated } from 'react-spring';
import { useEffect } from 'react';


const MenuButton = ({action}) => {

    const [{
        l1_x1, 
        l1_y1,
        l1_x2,
        l1_y2,
        l2_x1, 
        l2_y1,
        l2_x2,
        l2_y2,
        l3_x1, 
        l3_y1,
        l3_x2,
        l3_y2,
    }, setSpring] = useSpring(() => ({
        l1_x1: 0.25,
        l1_y1: 0.325,
        l1_x2: 0.75,
        l1_y2: 0.325,
        l2_x1: 0.25,
        l2_y1: 0.5,
        l2_x2: 0.75,
        l2_y2: 0.5,
        l3_x1: 0.25,
        l3_y1: 0.675,
        l3_x2: 0.75,
        l3_y2: 0.675,
        config: {
            duration: 125,
        }
    }))
    useEffect(() => {
        if (action === true) {
            setSpring.start({
                to: [
                    {
                    l1_y1: 0.125,
                    l1_y2: 0.5,
                    l2_y1: 0.3125,
                    l2_y2: 0.6875,
                    l3_y1: 0.875,
                    l3_y2: 0.5,
                    },
                    {
                    l1_y1: 0.25,
                    l1_y2: 0.75,
                    l2_y1: 0.25,
                    l2_y2: 0.75,
                    l3_y1: 0.75,
                    l3_y2: 0.25,
                    },
                ]
            })
        } else if (action === false) {
            setSpring.start({
                to: [
                    {
                    l1_y1: 0.125,
                    l1_y2: 0.5,
                    l2_y1: 0.3125,
                    l2_y2: 0.6875,
                    l3_y1: 0.875,
                    l3_y2: 0.5,
                    },
                    {
                    l1_y1: 0.325,
                    l1_y2: 0.325,
                    l2_y1: 0.5,
                    l2_y2: 0.5,
                    l3_y1: 0.675,
                    l3_y2: 0.675,
                    },
                ]
            })
        }

    });
    return <svg className={styles.svgCanvas}>
        <animated.line className={styles.line} 
        x1={l1_x1.to(x => (x*100)+"%")} 
        y1={l1_y1.to(x => (x*100)+"%")}
        x2={l1_x2.to(x => (x*100)+"%")}
        y2={l1_y2.to(x => (x*100)+"%")}  />
        <animated.line className={styles.line} 
        x1={l2_x1.to(x => (x*100)+"%")} 
        y1={l2_y1.to(x => (x*100)+"%")}
        x2={l2_x2.to(x => (x*100)+"%")}
        y2={l2_y2.to(x => (x*100)+"%")}  />
        <animated.line className={styles.line} 
        x1={l3_x1.to(x => (x*100)+"%")} 
        y1={l3_y1.to(x => (x*100)+"%")}
        x2={l3_x2.to(x => (x*100)+"%")}
        y2={l3_y2.to(x => (x*100)+"%")}  />
    </svg>
};

export default MenuButton;
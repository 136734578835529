// april update 22
import logo from './logo.svg';
import './styles/MainGrid.css';
import './styles/App.css';
import './styles/Type.css';
import './styles/AnimatedLink.css';
import Header from './components/Header';
import Footer from './components/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect } from 'react';
import pages from './pages';


function ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}

function App() {
  return (
    <Router>
      <ScrollToTop>
      <Header></Header>
        <Switch>
          {
            pages.map(p => (
              <Route key={p.key} path={p.path}>
                {p.jsx}
              </Route>
            ))
          }
        </Switch>
      <Footer></Footer>
      </ScrollToTop>
    </Router>
  );
}

export default App;

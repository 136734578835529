import ProjectCard from '../components/ProjectCard';
import Welcome from './Welcome';
import VSpace from '../components/VSpace';
import PageLine from '../components/PageLine';
import Banner from '../components/Banner';
import SeeMore from '../components/SeeMore';
import styles from '../styles/CoffeeBreak.module.css';


const CoffeeBreak = () => {
    return <div>
        <VSpace units={2}></VSpace>
        <PageLine text='Coffee Break'></PageLine>
        <VSpace units={1}></VSpace>
        <Banner src='media/coffeebreak/thumbnail.jpg'></Banner>
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <h1 style={{gridArea: "mid"}}>A Cup To Remember</h1>
        </div>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <p style={{gridArea: "mid"}}>
            Coffee Break is an educational YouTube channel producing video essays. It has amassed a following of over 350,000 subscribers to date. In the words of Stephen, the creator of the channel, it “explores ideas in the time it takes to drink your morning coffee.”
            <br></br><br></br>
            Between 2018 and 2019 I worked closely with the team behind Coffee Break with my efforts being focused on the future direction of the brand.
            <br></br>
            Amongst other things, my work has included producing a logo and intro animation, creating thumbnails and video editing.
            </p>
        </div>
        <VSpace units={4}></VSpace>
        <div className='mainGrid'>
        <div style={{
            gridArea: "mid",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <h2>
            "Martin has been the consummate professional and has helped re-imagine our brand image from the ground up. From day one he has gone above and beyond for this organization and has become an invaluable asset of our team."
            </h2>
            <VSpace units={1}></VSpace>
            <p>
            Stephen - Coffee Break Productions
            </p>

        </div>
        </div>
        <VSpace units={2}></VSpace>
        <PageLine text='Logo & Intro Animation'></PageLine>
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <img style={{width: "100%", gridArea: "1/left/1/right"}} src='media/coffeebreak/logo_showoff.jpg'></img>
        </div>
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <video
                style={{ gridArea: "1/left/1/right", width: "100%", zIndex: "-1" }}
                autoPlay
                loop
                controls
            >
            <source src="media/coffeebreak/video.mp4" type="video/mp4" />
            </video>
        </div>
        <VSpace units={2}></VSpace>
        <PageLine text='Selection of thumbnails'></PageLine>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <div style={{
                gridArea: "mid",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <img 
                className={styles.thumb} 
                src='media/coffeebreak/smiley.jpg'>
                </img>
                <VSpace></VSpace>
                <a href='https://www.youtube.com/watch?v=MVM3M3o2Nao'>
                    <h3>
                        YouTube: "Why quitting smoking is complicated"
                    </h3>
                </a>
                <VSpace units={2}></VSpace>
                <img 
                className={styles.thumb} 
                src='media/coffeebreak/astronaut.jpg'>
                </img>
                <VSpace></VSpace>
                <a href='https://www.youtube.com/watch?v=Vussgc-oeXk'>
                    <h3>
                        YouTube: "RedBulls Invisible Marketing"
                    </h3>
                </a>
                <VSpace units={2}></VSpace>
                <img 
                className={styles.thumb} 
                src='media/coffeebreak/tedtalk.jpg'>
                </img>
                <VSpace></VSpace>
                <a href='https://www.youtube.com/watch?v=D4PFrYteKxI'>
                    <h3>
                        YouTube: "The perfect TED talk that never happened"
                    </h3>
                </a>
                <VSpace units={2}></VSpace>

            </div>
        </div>
        <SeeMore></SeeMore>





    </div>
};

export default CoffeeBreak;
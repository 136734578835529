import ProjectCard from '../components/ProjectCard';
import Welcome from './Welcome';
import VSpace from '../components/VSpace';
import PageLine from '../components/PageLine';

const Home = () => {
    return <div>
        <Welcome></Welcome>
        <VSpace></VSpace>
        <PageLine text="What I'm up to now"></PageLine>
        <VSpace></VSpace>
        <div className='mainGrid'>
            <div style={{gridArea: "mid"}}>
                <a href='https://youtu.be/1EpQtVJb0OU' >
                    <h2>Rotation + Translation = Rotation.</h2>
                </a>
                <VSpace units={0.5}></VSpace>
                For the 3Blue1Brown "Summer of Math Exposition" contest, I created an animated proof showing that any transformation of the plane preserving orientation and distance is either a rotation or a translation. Selected as honorable mention.
                <VSpace units={1}></VSpace>

                <a href='https://youtu.be/3imeTgGBaLc?si=Vf89A3aIdw6UojAX' >
                    <h2>Prelude to Galois Theory: Exploring Symmetric Polynomials</h2>
                </a>
                <VSpace units={0.5}></VSpace>
                A video lecture created in the style of Bob Ross' "The Joy of Painting" series, exploring the so-called "Fundamental Theorem of Symmetric Polynomials" and its connection to Galois Theory.
                <VSpace units={1}></VSpace>




                <a href='https://mtrifonov.notion.site/Artin-s-Algebra-1st-Edition-Solutions-68dd73504b3b4895b93e2c6a9a8ad348' >
                    <h2>Artin's Algebra</h2>
                </a>
                <VSpace units={0.5}></VSpace>
                Im currently working my way through Michael Artin's "Algebra" (1st. Edition) and uploading solutions to selected exercises as I go along.
                <VSpace units={1}></VSpace>

                <a href='https://mtrifonov.notion.site/HyperPaintJS-5f5a60cbba30417787a510346c35eb22?pvs=4' >
                    <h2>HyperPaintJS</h2>
                </a>
                <VSpace units={0.5}></VSpace>
                HyperPaintJS is a variaton of the classic Turtle Graphics programming environment, with the added twist of allowing the placement of emojis on the canvas, allowing for more engaging exercises.
                
                

                <VSpace units={2}></VSpace>
            </div>
        </div>
        
        <PageLine text="Things I've made in the past"></PageLine>
        <VSpace></VSpace>
        <ProjectCard
            title = 'What Happens When We Dance?'
            description = 'Real-time Spotify music visualiser'
            path = 'tingles'
            thumb = 'media/tingles/thumbnail.jpg'
            bg = '#000000'
            accent = '#fd2577'
            mode = 'dark'
            tag = 'web dev'
        ></ProjectCard>
        <VSpace></VSpace>
        <ProjectCard
            title = 'Walls or Bridges?'
            description = 'The effect of border closures on the spread of corona virus'
            path = 'wallsorbridges'
            thumb = 'media/wallsorbridges/thumbnail.jpg'
            bg = '#bcc284'
            accent = '#7e5926'
            mode = 'light'
            tag = 'web dev'
        ></ProjectCard>
        <VSpace></VSpace>
        <ProjectCard
            title='The Perfect TED Talk'
            description = '... that never happened'
            path='ted'
            thumb = 'media/ted/thumb.jpg'
            tag = 'video essay'
            bg='#d60000'
            accent='#5b0000'
            mode='light'
        ></ProjectCard>
        <VSpace></VSpace> 
        <ProjectCard
            title='A Cup To Remember'
            bg='#663123'
            accent='#df4548'
        ></ProjectCard>
        <VSpace></VSpace>         
        <ProjectCard
            title = 'Martin Trifonov (the bear)'
            description = 'My namesake'
            path = 'bear'
            thumb = 'media/bear/thumbnail.jpg'
            bg = '#e2cac2'
            accent = '#a21600'
            mode = 'light'
            tag = 'fine art'
        ></ProjectCard>
        <VSpace></VSpace>      
        <ProjectCard
            title = 'The Editors Eye'
            description = 'Rebranding the YouTube channel This Guy Edits '
            path = 'thisguyedits'
            thumb = 'media/thisguyedits/thumbnail.jpg'
            bg = '#808cff'
            accent = '#1f2241'
            mode = 'light'
            tag = 'branding'
        ></ProjectCard>
        <VSpace></VSpace>     
        <ProjectCard
            title = 'EUROPA®'
            description = 'A satirical take on the future of Europa'
            path = 'europa'
            thumb = 'media/europa/thumbnail.jpg'
            bg = '#ffbc3f'
            accent = '#260f9b'
            mode = 'light'
            tag = 'fine art'
        ></ProjectCard>
        <VSpace></VSpace>  
        <ProjectCard
            title = 'Making Sense Of Branding'
            description = 'Reflecting on contemporary branding thought'
            path = 'media/msob/msob_ebook.pdf'
            thumb = 'media/msob/thumbnail.jpg'
            bg = '#a2a2a2'
            accent = '#3d3d3d'
            mode = 'light'
            tag = 'branding'
        ></ProjectCard>
    </div>
};

export default Home;
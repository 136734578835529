import styles from '../styles/Welcome.module.css';
import VSpace from '../components/VSpace';

const Welcome = () => {
    return <div><div className='mainGrid'>
        <div className={styles.welcome}>

            <img className={styles.img} src='media/about/profile.jpg'></img>
            <div className={styles.text}>
                <h1>Hello!<br></br>I'm Martin.</h1>
                <VSpace units={0.75}></VSpace>
                <div>
                I like making things and learning a little
                bit more about the world along the way.
                </div>
            
            </div>
        </div>
    </div>
    <VSpace units={2}></VSpace>
    </div>
};

export default Welcome;
import Home from './pages/Home.js';
import CoffeeBreak from './pages/CoffeeBreak.js';
import ThisGuyEdits from './pages/ThisGuyEdits.js';
import Tingles from './pages/Tingles.js';
import WallsOrBridges from './pages/WallsOrBridges.js';
import Bear from './pages/Bear.js';
import Europa from './pages/Europa.js';
import Ted from './pages/Ted.js';

const pages = [
    {
        path: "/home",
        jsx: Home,
        key: "home",
    },
    {
        path: "/coffeebreak",
        jsx: CoffeeBreak,
        key: "coffeebreak",
    },
    {
        path: "/europa",
        jsx: Europa,
        key: "europa",
    },
    {
        path: "/thisguyedits",
        jsx: ThisGuyEdits,
        key: "thisguyedits",
    },
    {
        path: "/tingles",
        jsx: Tingles,
        key: "tingles",
    },
    {
        path: "/wallsorbridges",
        jsx: WallsOrBridges,
        key: "wallsorbridges",
    },
    {
        path: "/bear",
        jsx: Bear,
        key: "bear",
    },
    {
        path: "/ted",
        jsx: Ted,
        key: "ted",
    },
    {
        path: "/",
        jsx: Home,
        key: "entry",
    },
]



export default pages;
import ProjectCard from '../components/ProjectCard';
import Welcome from './Welcome';
import VSpace from '../components/VSpace';
import PageLine from '../components/PageLine';
import Banner from '../components/Banner';
import SeeMore from '../components/SeeMore';
import styles from '../styles/Bear.module.css';


const Europa = () => {
    return <div>
        <VSpace units={2}></VSpace>
        <PageLine text='EUROPA®'></PageLine>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <div style={{
                gridArea: '1/left/1/right',
                width: "100%",
                position: "relative",
                paddingTop: "56.25%",
            }}>
                <iframe 
                style={{
                    top: "0",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                }}
                src="https://player.vimeo.com/video/359995262" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                </iframe>
            </div>
        </div>
            
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <h1 style={{gridArea: "mid", maxWidth: "500px",}}>
            Step inside EUROPA®!
            </h1>
        </div>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <p style={{gridArea: "mid"}}>
            This project imagines a future Europe, in which borders have been abolished, 
            and nationalism has been replaced by continentalism. 
            A satirical, tongue-in-cheek commentary on today’s world. <br></br><br></br>
            Co-created with <a href='https://whoisedgar.com'>Edgar</a>, Natalie Davidson, Dani Orefo and Roni Spann.
            </p>
        </div>
        <SeeMore></SeeMore>





    </div>
};

export default Europa;
import ProjectCard from '../components/ProjectCard';
import Welcome from './Welcome';
import VSpace from '../components/VSpace';
import PageLine from '../components/PageLine';
import Banner from '../components/Banner';
import SeeMore from '../components/SeeMore';
import styles from '../styles/Bear.module.css';


const Bear = () => {
    return <div>
        <VSpace units={2}></VSpace>
        <PageLine text='Martin Trifonov (the bear)'></PageLine>
        <VSpace units={1}></VSpace>
        {/* <Banner src='media/bear/thumbnail.jpg'></Banner>
         */}
        <div className='mainGrid'>
            <img style={{ width: "100%", gridArea: "1/left/1/right" }} src='media/bear/example.jpg'></img>
        </div>
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <h1 style={{gridArea: "mid"}}>Martin Trifonov (the bear)</h1>
        </div>
        <VSpace units={1}></VSpace>
        {/* <div className='mainGrid'>
            <h1 style={{gridArea: "mid", maxWidth: "500px",
            textIndent:"-15px"}}>
                "When Martin Trifonov woke up one day from unsettling dreams,
                he found himself changed in his bed into a bear."
            </h1>
        </div>
        <VSpace units={1}></VSpace> */}
        <div className='mainGrid'>
            <p style={{ gridArea: "mid" }}>

                Martin Trifonov (the bear) has an Instagram account,
                and follows only people that have 'Martin Trifonov' in their
                user name. <br></br><br></br>
                Martin Trifonov (the bear) is a fine art project
                for Martin Trifonov's, by Martin Trifonov, exploring
                naming conventions, identity, and the absurd.<br></br>
                <a href='https://www.instagram.com/martintrifonov_thebear/'>
                    Follow Martin Trifonov (the bear) here.
                </a>

            </p>
        </div>

        <VSpace units={2}></VSpace>
        <PageLine text='Selection of posts'></PageLine>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <div style={{
                gridArea: "mid",
            }} className={styles.box}>
                <img
                    className={styles.el}
                    src='media/bear/1.jpg'>
                </img>
                <img
                    className={styles.el}
                    src='media/bear/3.jpg'>
                </img>
                <img
                    className={styles.el}
                    src='media/bear/4.jpg'>
                </img>
                <img
                    className={styles.el}
                    src='media/bear/5.jpg'>
                </img>
                <img
                    className={styles.el}
                    src='media/bear/6.jpg'>
                </img>
                <img
                    className={styles.el}
                    src='media/bear/7.jpg'>
                </img>
                <img
                    className={styles.el}
                    src='media/bear/8.jpg'>
                </img>
                <img
                    className={styles.el}
                    src='media/bear/2.jpg'>
                </img>
                <img
                    className={styles.el}
                    src='media/bear/9.jpg'>
                </img>


            </div>
        </div>
        {/* <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <img style={{width: "100%", gridArea: "1/left/1/right"}} src='media/bear/example.jpg'></img>
        </div> */}
        <SeeMore></SeeMore>





    </div>
};

export default Bear;
import ProjectCard from '../components/ProjectCard';
import Welcome from './Welcome';
import VSpace from '../components/VSpace';
import PageLine from '../components/PageLine';
import Banner from '../components/Banner';
import SeeMore from '../components/SeeMore';
import styles from '../styles/Bear.module.css';


const Bear = () => {
    return <div>
        <VSpace units={2}></VSpace>
        <PageLine text='This Guy Edits'></PageLine>
        <VSpace units={1}></VSpace>
        <Banner src='media/thisguyedits/preview.jpg' nooffset={true}></Banner>
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <h1 style={{gridArea: "mid", maxWidth: "500px",}}>
                The Editors Eye
            </h1>
        </div>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <p style={{gridArea: "mid"}}>

            Sven is an A.C.E. Award-nominated editor who cut for James Cameron,
            Joseph Gordon-Levitt, and James Franco.<br></br><br></br>
            On YouTube, he runs the channel <a href='https://www.youtube.com/c/ThisGuyEdits/'>This Guy Edits</a>,
            where he teaches the art of editing to a following of
            over 400k subscribers.
            I helped Sven rebrand his channel by 
            creating a consistent visual identity,
            including the logo shown above.
            </p>
        </div>
        <SeeMore></SeeMore>





    </div>
};

export default Bear;
import styles from '../styles/ProjectCard.module.css';
import VSpace from '../components/VSpace';
import Tag from '../components/Tag';
import {
    Link
} from "react-router-dom";

const ProjectCard = ({
    path = 'coffeebreak',
    thumb = 'media/coffeebreak/thumbnail.jpg',
    bg = '#a20204',
    accent = '#ecbbae',
    mode = 'dark',
    title = 'A cup to remember',
    description = 'Rebranding the YouTube channel Coffee Break',
    tag = 'branding',

}) => {
    mode = mode === 'dark' ? 'white' : 'black'
    return <div className='mainGrid'>
        <a href={path}
        style={{textDecoration: "none"}}
        className={`aLinkThick aLink aLink_${mode} ${styles.projectCard}`}>
            <img src={thumb} className={styles.thumbnail}></img>
            <div style={{
                backgroundColor: bg,
            }} className={styles.description}>
                <VSpace units={1}></VSpace>
                <h1 style={{color: mode}}>
                    <span>{title}</span>
                </h1>
                <VSpace units={0.5}></VSpace>
                <div style={{color: accent}}>
                    {description}
                </div>
                <VSpace units={0.5}></VSpace>
                <div><Tag accent={accent} tag={tag}></Tag></div>
                <VSpace units={1.5}></VSpace>
            </div>
        </a>
    </div>
};

export default ProjectCard;
import ProjectCard from '../components/ProjectCard';
import Welcome from './Welcome';
import VSpace from '../components/VSpace';
import PageLine from '../components/PageLine';
import Banner from '../components/Banner';
import SeeMore from '../components/SeeMore';
import styles from '../styles/CoffeeBreak.module.css';


const Tingles = () => {
    return <div>
        <VSpace units={2}></VSpace>
        <PageLine text='Unpredictable Tingles'></PageLine>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <div style={{
                gridArea: '1/left/1/right',
                width: "100%",
                position: "relative",
                paddingTop: "56.25%",
            }}>
                <iframe
                style={{
                    top: "0",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                }}
                src="https://www.youtube.com/embed/Anu72c5ZunE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </div>
        </div>
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <h1 style={{gridArea: "mid"}}>What Happens When We Dance?</h1>
        </div>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <p style={{gridArea: "mid"}}>
            There is something magical about dancing. 
            As humans, we pick up on the rhythm of a song and we understand its progression. 
            Emotionally, as well as intuitively, we translate our perception of music into movement. 
            <a href='https://tingles.wtf'>Tingles.wtf</a> is a website that generates automated music videos to whatever you’re playing on Spotify right now. 
            It translates audio analysis data provided by the Spotify Web API into movement, which it applies to predefined visuals. In a sense, this project is an attempt to teach algorithms to dance. 
            </p>
        </div>
        <VSpace units={2}></VSpace>
        <PageLine text='Some links'></PageLine>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <div style={{gridArea: "mid"}}>
                <a href='https://tingles.wtf' >
                    <h2>Try the website out here</h2>
                </a>
                <VSpace units={1}></VSpace>
                <a href='/media/tingles/musictomovement.pdf'>
                    <h2>Mapping Music To Movement (pdf)</h2>
                </a>
                <VSpace units={1}></VSpace>
                <a href='/media/tingles/unpredictabletingles.pdf' style={{gridArea: "mid"}}>
                    <h2>Are you ready for unpredictable tingles? (pdf)</h2>
                </a>
                <VSpace units={1}></VSpace>
                <a href='https://gitlab.com/mtrifonov/unpredictable-tingles' style={{gridArea: "mid"}}>
                    <h2>Gitlab</h2>
                </a>
            </div>
        </div>
        <SeeMore></SeeMore>





    </div>
};

export default Tingles;
import ProjectCard from '../components/ProjectCard';
import Welcome from './Welcome';
import VSpace from '../components/VSpace';
import PageLine from '../components/PageLine';
import Banner from '../components/Banner';
import SeeMore from '../components/SeeMore';
import styles from '../styles/CoffeeBreak.module.css';


const Ted = () => {
    return <div>
        <VSpace units={2}></VSpace>
        <PageLine text='The Perfect TED Talk'></PageLine>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <div style={{
                gridArea: '1/left/1/right',
                width: "100%",
                position: "relative",
                paddingTop: "56.25%",
            }}>
                <iframe
                style={{
                    top: "0",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                }}
                src="https://www.youtube.com/embed/D4PFrYteKxI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </div>
            </div>
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <h1 style={{gridArea: "mid",maxWidth: "500px"}}>The Perfect TED Talk That Never Happened</h1>
        </div>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <p style={{gridArea: "mid"}}>
            As part of <a href='/coffeebreak'>my work for the educational YouTube channel Coffee Break</a>,
            I was responsible for the art direction of “The Perfect TED Talk That Never Happened” 
            - a short video essay illustrating the importance of replication studies in science.
            
            </p>
        </div>
        <SeeMore></SeeMore>





    </div>
};

export default Ted;
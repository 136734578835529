import styles from '../styles/Nav.module.css';
import VSpace from './VSpace';

const Nav = () => {
    return <div className={styles.nav}>
            <VSpace units={4}></VSpace>
            <a className={`aLinkThick aLink aLink_black`} href='/'>
                <h1><span>Work</span></h1>
            </a>
            <a className={`aLinkThick aLink aLink_black`} href='https://mtrifonov.notion.site/mtrifonov/Martin-Trifonov-910d65b2c7054243a136ce461635c6d9'>
                <h1><span>CV</span></h1>
            </a>
            <a className={`aLinkThick aLink aLink_black`} href='mailto:mtrifonov.design@gmail.com'>
            <h1><span>Email</span></h1>
            </a>
            <a className={`aLinkThick aLink aLink_black`} href='https://gitlab.com/mtrifonov'>
                <h1><span>Gitlab</span></h1>
            </a>
            <a className={`aLinkThick aLink aLink_black`} href='https://www.linkedin.com/in/martin-trifonov-945581196/'>
                <h1><span>LinkedIn</span></h1>
            </a>

            <VSpace units={6}></VSpace>
        </div>
};

export default Nav;
import styles from '../styles/Header.module.css';
import Nav from './Nav';
import MenuButton from './MenuButton';
import { useSpring, animated } from 'react-spring';
import { useEffect, useState, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const Header = () => {

    const [state, setState] = useState(0);

    const [spring, setSpring] = useSpring(() => ({
        transformNav: "translate(100%,0%)",
        transformSlider: "translate(100%,0%)",
        opacity: 0,
    }));

    useEffect(() => {
        if (state) {
            setSpring.start({
                to: [{
                    opacity: 0,
                    transformNav: "translate(15%,0%)",
                    transformSlider: "translate(0%,0%)",
                    immediate: true,
                },{
                    opacity: 1,
                    transformNav: "translate(0%,0%)",
                    immediate: false,
                },],
                
            });


        } else if (state === false) {
            setSpring.start({
                to: [{
                    transformNav: "translate(15%,0%)", 
                    opacity: 0,
                    immediate: false,
                },{

                    transformSlider: "translate(100%,0%)", 
                    immediate: true,
                }],

            });
        }

    })

    return <div>
        <div style={styles} className={`${styles.header} mainGrid`}>
            <div className={styles.headerInside}>        
                <a href='/' className={`${styles.name} aLinkThin aLink aLink_black`}><span>Martin Trifonov</span></a>
                <div className={styles.nav} onClick={() => setState(state === 0 ? true : !state)}>
                <MenuButton action={state}></MenuButton>
                </div>
            </div>
        </div>
        <div className={styles.headerCushion}></div>
        <animated.div 
        className={styles.slider}
        style={{...{
            transform: spring.transformSlider,
            opacity: spring.opacity,
        },...{
            position: "fixed",
            width: "100%",
            backgroundColor: "white",
            
        }}}>
            <animated.div
            style={{
            transform: spring.transformNav,
            opacity: spring.opacity,}}>
                <Nav></Nav>
            </animated.div>
        </animated.div>
    </div>
};

export default Header;
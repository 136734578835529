import styles from '../styles/SeeMore.module.css';
import VSpace from './VSpace.js';
import PageLine from '../components/PageLine';
import { useLayoutEffect, useRef, useState } from 'react';

const Footer = () => {
    const ref1 = useRef();
    const ref2 = useRef();

    const [state, setState] = useState({width1:null,});
    useLayoutEffect(() => {
        const rect1 = ref1.current.getBoundingClientRect();
        const rect2 = ref2.current.getBoundingClientRect();
        setState({
            width1: rect1.width,
            height1: rect1.height,
            width2: rect2.width,
            height2: rect1.height
        });
    },[state.width1]);

    const calcWidth1 = state.width1 === null ? 0 : 100-
    ((state.width1)*(100/(state.height1 === 0 ? 1 : state.height1)));
    const calcWidth2 = state.width1 === null ? 0 : 100-
    ((state.width2)*(100/(state.height2 === 0 ? 1 : state.height2)))
    
    return <div>
        <VSpace units={6}></VSpace>
        <div className={`${styles.seeMore} mainGrid`}>
        <div className={`${styles.content}`}>
            <a className={`aLinkThick aLink aLink_black ${styles.arrowBox}`} href='/'>
                    <span className={`${styles.text}`}>
                    Back to portfolio
                    </span>
                <div
                className={`${styles.left}`}
                >
                    <svg 
                    ref={ref2}
                    className={`${styles.arrow}`}
                    viewBox={`${calcWidth2} 0 100 100`}
                    preserveAspectRatio="xMinYMin"
                    >
                        <path
                        d={`
                        M 100 50 
                        L 55 100
                        L 45 100
                        L 80 55
                        L ${calcWidth2} 55
                        L ${calcWidth2} 45
                        L 80 45
                        L 45 0
                        L 55 0
                        L 100 50 
                        z 
                        `}></path>
                    </svg>
                </div>
            </a>
            <VSpace units={1.5}></VSpace>
            <a className={`aLinkThick aLink aLink_black ${styles.arrowBox}`} 
            href='mailto:mtrifonov.design@gmail.com'>
                    <span className={`${styles.text}`}>
                    Get in touch
                    </span>
                <div
                className={`${styles.right}`}
                >
                    <svg 
                    ref={ref1}
                    className={`${styles.arrow}`}
                    viewBox={`${calcWidth1} 0 100 100`}
                    preserveAspectRatio="xMinYMin"
                    >
                        <path
                        d={`
                        M 100 50 
                        L 55 100
                        L 45 100
                        L 80 55
                        L ${calcWidth1} 55
                        L ${calcWidth1} 45
                        L 80 45
                        L 45 0
                        L 55 0
                        L 100 50 
                        z 
                        `}></path>
                    </svg>
                </div>

            </a>
        </div>

    </div>
    </div>
};

export default Footer;
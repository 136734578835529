import styles from '../styles/PageLine.module.css';
import VSpace from './VSpace';

const PageLine = ({text = null}) => {
    if (text === null) {
        return <div className='mainGrid'>
            <div className={styles.line}></div>
        </div>
    }
    return <div>
        <div className='mainGrid'>
            <div className={styles.text}>{text}</div>
        </div>
        <VSpace></VSpace>
        <div className='mainGrid'>
            <div className={styles.line}></div>
        </div>
    </div>

};

export default PageLine;
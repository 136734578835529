import styles from '../styles/Tag.module.css';
import VSpace from './VSpace.js';

const Tag = ({ tag="branding", accent="#a9928c"}) => {
    tag = tag.toUpperCase();
    return <div className={styles.tag}
    style={{
        border: `1px solid ${accent}`,
        color: accent,
    }}
    >
        {tag}
    </div>
};

export default Tag;
import styles from '../styles/Banner.module.css';
import VSpace from '../components/VSpace';

const Banner = ({
    src = 'media/coffeebreak/thumbnail.jpg',
    nooffset = false,

}) => {

    return <div className='mainGrid'>
        <div className={styles.banner}>
            <img src={src} className={styles.bannerImage}
            style={{width:nooffset ? "100%" : "120%"}}></img>
        </div>
    </div>
};

export default Banner;
import ProjectCard from '../components/ProjectCard';
import Welcome from './Welcome';
import VSpace from '../components/VSpace';
import PageLine from '../components/PageLine';
import Banner from '../components/Banner';
import SeeMore from '../components/SeeMore';
import styles from '../styles/CoffeeBreak.module.css';


const WallsOrBridges = () => {
    return <div>
        <VSpace units={2}></VSpace>
        <PageLine text='Walls or Bridges?'></PageLine>
        <VSpace units={1}></VSpace>
        <Banner src='media/wallsorbridges/preview.jpg' nooffset={true}></Banner>
        <VSpace units={2}></VSpace>
        <div className='mainGrid'>
            <h1 style={{gridArea: "mid"}}>How effective are border closures?</h1>
        </div>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <p style={{gridArea: "mid"}}>
            An interactive simulation modelling the effect of border closures and social distancing measures on the spread of coronavirus in Europe.
            Developed as part of the "Wir vs Virus" hackathon, together with
            Julian Wykowski and Jannis Nikoulaides.
            </p>
        </div>
        <VSpace units={2}></VSpace>
        <PageLine text='Some links'></PageLine>
        <VSpace units={1}></VSpace>
        <div className='mainGrid'>
            <div style={{gridArea: "mid"}}>
                <a href='https://wallsbridges.appspot.com/' >
                    <h2>Try the website out here</h2>
                </a>
                <VSpace units={1}></VSpace>
                <a href='https://wallsbridges.appspot.com/report.pdf'>
                    <h2>Read more about this project (pdf)</h2>
                </a>
                <VSpace units={1}></VSpace>
                <a href='https://www.youtube.com/watch?v=aWa1C1PVag0' style={{gridArea: "mid"}}>
                    <h2>YouTube video explaining the simulation</h2>
                </a>
            </div>
        </div>
        <SeeMore></SeeMore>





    </div>
};

export default WallsOrBridges;
import styles from '../styles/Footer.module.css';
import VSpace from './VSpace.js';

const Footer = () => {
    return <div>
        <div className='mainGrid'>
            <div className={styles.copyright}>
                <VSpace units={2}></VSpace>
                © Martin Trifonov, 2022
                <VSpace units={2}></VSpace>
            </div>
        </div>
    </div>
};

export default Footer;